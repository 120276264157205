// remove eslint ignore when existing instance of axious will be removed
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';

import {
  applyAccessToken,
  applyLocalAccessToken,
} from './middlewares/applyAccessToken.js';
import { requestDataToSnakeCase2 } from './middlewares/requestDataToSnakeCase.js';
import { responseDataToCamelCase2 } from './middlewares/responseDataToCamelCase.js';
import { responseErrorInterceptor } from './middlewares/responseErrorInterceptor.js';

export const newApi = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

// This API client is used when should ignore addition token (workspace) in request
export const profileApi = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

// This API client is used by the `@mint-lib/auth` package.
export const guestApi = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

newApi.interceptors.request.use(requestDataToSnakeCase2, (error) =>
  Promise.reject(error),
);
newApi.interceptors.request.use(applyAccessToken, (error) =>
  Promise.reject(error),
);

profileApi.interceptors.request.use(applyLocalAccessToken, (error) =>
  Promise.reject(error),
);

newApi.interceptors.response.use(
  responseDataToCamelCase2,
  responseErrorInterceptor,
);
